import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { showToast } from '../../components/atoms/Toast/Toast';

import { axiosInstance } from '../../utils/axiosInstance';
import { triggerEvent } from '../../utils';
import { type IPhoneVerificationData } from '../../locales/@interfaces/phoneVerification/phoneVerification';
import { type TLocale } from '../../modules/shared/@interfaces';

export type TResendPinStrategy = 'sms' | 'whatsapp';

export type TPhoneVerification = IPhoneVerificationData;

interface IHandleSubmitNewPhoneNumberResponse {
  status: string;
}

export interface IPhoneVerificationContext {
  values: {
    phoneVerificationTitle: string;
    phoneVerificationData: TPhoneVerification;
    isPinSending: boolean;
    isPinInvalid: boolean;
    isCountdownOver: boolean;
    isShouldResetCountdown: boolean;
    locale: TLocale;
  };
  methods: {
    handleResendPin: (strategy: TResendPinStrategy) => void;
    onOpenChangeNumberModal: (modalIsOpen: boolean) => void;
    onIsCountdownOver: () => void;
    onCountdownIsReset: () => void;
    onPinSubmit: (pin: string) => void;
    onSubmitNewPhoneNumber: (
      phone: string
    ) => Promise<IHandleSubmitNewPhoneNumberResponse>;
  };
}

interface IPhoneVerificationProviderProps {
  children: ReactNode;
  phoneVerificationData: TPhoneVerification;
  phoneId: string;
  uuid: string;
  locale: TLocale;
}

interface IResendPinByResponse {
  message: string;
}

interface IPinAPIResponse {
  link: string;
}

export const PhoneVerificationContext = createContext(
  {} as IPhoneVerificationContext
);

export const PhoneVerificationProvider = ({
  children,
  phoneVerificationData,
  phoneId,
  uuid,
  locale,
}: IPhoneVerificationProviderProps) => {
  const [isPinSending, setIsPinSending] = useState(false);
  const [isPinInvalid, setIsPinInvalid] = useState(false);
  const [isCountdownOver, setIsCountdownOver] = useState(false);
  const [isShouldResetCountdown, setIsShouldResetCountdown] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    phoneVerificationData.initialPhone
  );

  const phoneVerificationTitle = phoneNumber
    ? `${phoneVerificationData.title.withPhone} ${phoneNumber}`
    : phoneVerificationData.title.withoutPhone;

  const onOpenChangeNumberModal = useCallback(
    (modalIsOpen: boolean) => {
      if (modalIsOpen) {
        triggerEvent('phone_verification-change_number_modal_opened', {
          phoneNumber,
        });
      }
    },
    [phoneNumber]
  );

  const onIsCountdownOver = useCallback(() => {
    setIsCountdownOver(true);
  }, []);

  const onCountdownIsReset = useCallback(() => {
    setIsShouldResetCountdown(false);
  }, []);

  const handlePinSubmit = useCallback(
    async (pin: string) => {
      setIsPinInvalid(false);
      setIsPinSending(true);

      const requestOptions = {
        url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/phone-verification/pin`,
        method: 'POST',
        data: {
          pin,
          phoneId,
          uuid,
        },
      } as const;

      try {
        const response = await axiosInstance<IPinAPIResponse>(requestOptions);

        const linkToRedirectToPostRequest = response.data.link;

        if (linkToRedirectToPostRequest) {
          triggerEvent('phone_verification-send_pin', {
            status: 'success',
            phoneNumber,
          });

          window.location.assign(linkToRedirectToPostRequest);

          return;
        }

        setIsPinInvalid(true);
        setIsPinSending(false);
      } catch (error) {
        setIsPinInvalid(true);
        setIsPinSending(false);

        triggerEvent('phone_verification-send_pin', {
          status: 'failure',
          phoneNumber,
        });
      }
    },
    [phoneId, phoneNumber, uuid]
  );

  const handleSubmitNewPhoneNumber = useCallback(
    async (
      phoneNumberParam: string
    ): Promise<IHandleSubmitNewPhoneNumberResponse> => {
      try {
        const response =
          await axiosInstance<IHandleSubmitNewPhoneNumberResponse>({
            url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/phone-verification/change`,
            method: 'POST',
            data: {
              phone: phoneNumberParam,
              phoneId,
              uuid,
            },
          });

        const { status } = response.data;

        const isSuccessInSubmitNewPhoneNumber = status === 'ok';

        if (isSuccessInSubmitNewPhoneNumber) {
          setPhoneNumber(phoneNumberParam);

          return {
            status,
          };
        }

        showToast({
          message: phoneVerificationData.resend.modal.messages.error,
          type: 'error',
          id: 'change-number-error',
        });

        return {
          status: 'error',
        };
      } catch (error) {
        showToast({
          message: phoneVerificationData.resend.modal.messages.error,
          type: 'error',
          id: 'change-number-error',
        });

        return {
          status: 'error',
        };
      }
    },
    [phoneId, phoneVerificationData.resend.modal.messages.error, uuid]
  );

  const handleResendPin = useCallback(
    async (strategy: TResendPinStrategy) => {
      try {
        const { data } = await axiosInstance<IResendPinByResponse>({
          url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/phone-verification/resend`,
          method: 'POST',
          data: {
            phoneId,
            resendStrategy: strategy,
            uuid,
          },
        });

        const { message } = data;

        setIsShouldResetCountdown(true);
        setIsCountdownOver(false);

        showToast({
          message: message,
          type: 'success',
          id: 'resendPin',
        });
      } catch (error) {
        showToast({
          message: phoneVerificationData.resend.errorMessage,
          type: 'error',
          id: 'resendPin',
        });
      } finally {
        triggerEvent('phone_verification-resend_pin', {
          phoneNumber,
        });
      }
    },
    [phoneId, phoneNumber, phoneVerificationData.resend.errorMessage, uuid]
  );

  return (
    <PhoneVerificationContext.Provider
      value={{
        values: {
          phoneVerificationTitle,
          isPinSending,
          isPinInvalid,
          phoneVerificationData,
          isCountdownOver,
          isShouldResetCountdown,
          locale,
        },
        methods: {
          handleResendPin,
          onOpenChangeNumberModal,
          onIsCountdownOver,
          onCountdownIsReset,
          onPinSubmit: handlePinSubmit,
          onSubmitNewPhoneNumber: handleSubmitNewPhoneNumber,
        },
      }}
    >
      {children}
    </PhoneVerificationContext.Provider>
  );
};

export function usePhoneVerification() {
  return useContext(PhoneVerificationContext);
}
