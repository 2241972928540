import { useEffect } from 'react';

import {
  Header,
  AppBanner,
  Container,
  Row,
  Col,
  Button,
  Paragraph,
  Banner,
  Footer,
  Breadcrumb,
} from 'darrius';

import {
  HeadContent,
  CategoryForm,
  CategoryRating,
  ProCard,
  AveragePrice,
  SimilarRequest,
  LinkList,
  FlatCard,
  FlatCardBody,
  ConfirmationBadge,
} from '../../components';

import { useRectForm } from '../../hooks/useRectForm';
import { useClientUser } from '../../hooks/useClientUser';

import { getAppBannerLink } from '../../utils/getAppBannerLink';
import { getFlashTitle } from '../../utils/getFlashTitle';
import { triggerEvent } from '../../utils';

import styles from './BudgetTemplate.module.scss';

import { type IBudgetProps } from '../../pages/orcamento/[...slug]';

export const BudgetTemplate = (props: IBudgetProps) => {
  const {
    meta,
    headerContent,
    categorySlug,
    mainSection,
    mainRequests,
    formData,
    ratings,
    ctaPro,
    proList,
    averagePrices,
    similarRequests,
    relatedLinks,
    footerContent,
    userData,
  } = props;

  const { isFinalForm, isMiddleForm } = useRectForm();

  const { handleUserSignOut } = useClientUser({ shouldFetchUserData: false });

  const bannerLink = getAppBannerLink('BUDGET');

  const flashTitle = getFlashTitle(userData.notificationCount);

  useEffect(() => {
    if (isMiddleForm) {
      triggerEvent('json_form-middle_form');
    }
  }, [isMiddleForm]);

  useEffect(() => {
    if (isFinalForm) {
      triggerEvent('json_form-final_form');
    }
  }, [isFinalForm]);

  return (
    <>
      <AppBanner link={bannerLink} />

      <HeadContent
        flashTitle={flashTitle}
        metaTitle={meta.title}
        metaDescription={meta.description}
        keywords={meta.keywords}
        canonicalLink={meta.canonical}
        pageType="requests/public:show"
        slug={categorySlug}
      />

      <Header.Root>
        <Header.NavMenu notificationsTotalCount={userData.notificationCount}>
          <Header.Link {...headerContent.signup} />

          <Header.Auth
            isLogged={!!userData}
            onLogout={handleUserSignOut}
            user={{
              avatar: userData?.avatar,
              name: userData?.name,
              phone: userData?.phone,
            }}
          >
            <Header.AuthItem
              text="Mensagens"
              link="/mensagens"
              notificationCount={userData.notificationCount}
            />
          </Header.Auth>
        </Header.NavMenu>
      </Header.Root>

      <main
        className={`${styles['category-page']} category-page`}
        data-category-slug={categorySlug}
      >
        <Container className={`${styles['main-section']} main-section`}>
          <Row className="bottom-sm">
            <Col className="col-sm-12">
              <Breadcrumb
                breadcrumbs={mainSection.breadcrumbs.items}
                className="hide-sm"
              />

              <script
                id="budget-configs"
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: mainSection.breadcrumbs.structuredData,
                }}
              />

              <Paragraph
                title={mainSection.title}
                text={mainSection.subtitle}
              />
            </Col>
          </Row>
        </Container>

        <Banner className={`${styles['form-and-ratings']} form-and-ratings`}>
          <Row className="space-between">
            <Col
              className={`${styles['form-area']} form-area col-sm-12 col-md-8`}
            >
              <CategoryForm {...formData} />

              <div className="space-top">
                <Paragraph title={mainRequests.title} size="3" />
                {mainRequests.items.map((item, id) => (
                  <FlatCard key={id}>
                    <FlatCardBody>
                      <Paragraph
                        className={`${styles['form-and-ratings__text']} form-and-ratings__text`}
                        text={item.cliName}
                        size="4"
                      />
                      <Paragraph text={item.text} size="4" />
                      <ConfirmationBadge
                        text="Pedido atendido"
                        alt="Pedido atendido"
                        size={24}
                      />
                    </FlatCardBody>
                  </FlatCard>
                ))}
              </div>
            </Col>

            <Col
              className={`${styles.ratings} ratings col-sm-12 col-md-4 center-sm`}
            >
              <CategoryRating {...ratings} />
            </Col>
          </Row>
        </Banner>

        {ctaPro && (
          <Banner className="cta-pro center-md">
            <Paragraph title={ctaPro.title} text={ctaPro.subtitle} size="2" />

            <Row className="center-md">
              <Col className="col-sm-12 col-md-5">
                <Button.Link href={ctaPro.link.url}>
                  {ctaPro.link.text}
                </Button.Link>
              </Col>
            </Row>
          </Banner>
        )}

        {proList && (
          <Banner className={`${styles['pro-list']} pro-list`}>
            <Paragraph
              title={proList.title}
              text={proList.subtitle}
              size="2"
              headline
            />

            <Row className="space-between slider-sm center-sm">
              {proList.items.map((pro) => (
                <Col key={pro.id} className="col-sm-12 col-md-4">
                  <ProCard {...pro} />
                </Col>
              ))}
            </Row>
          </Banner>
        )}

        {averagePrices && (
          <Banner className="average-prices">
            <Paragraph
              title={averagePrices.title}
              text={averagePrices.subtitle}
              size="2"
              headline
            />

            <Row className="space-between center-sm slider-sm">
              {averagePrices.items.map((price) => (
                <Col key={price.id} className="col-sm-12 col-md-4 col-lg-3">
                  <AveragePrice
                    link={price.link}
                    service={price.service}
                    minimum={price.prices.min}
                    average={price.prices.average}
                    maximum={price.prices.max}
                  />
                </Col>
              ))}
            </Row>
          </Banner>
        )}

        {similarRequests && (
          <Banner className="similar-requests">
            <Paragraph
              title={similarRequests.title}
              text={similarRequests.subtitle}
              size="2"
              headline
            />

            <Row className="space-between center-sm slider-sm">
              {similarRequests.items.map((request) => (
                <Col
                  key={request.id}
                  className="col-sm-12 col-md-4 col-lg-3 start-sm"
                >
                  <SimilarRequest {...request} />
                </Col>
              ))}
            </Row>
          </Banner>
        )}

        {relatedLinks.map((section) => (
          <LinkList
            key={section.id}
            title={section.title}
            links={section.links}
          />
        ))}
      </main>

      <Footer data={footerContent} />
    </>
  );
};
