import Image from 'next/image';
import {
  Header,
  Row,
  Col,
  Banner,
  Container,
  Paragraph,
  Footer,
} from 'darrius';

import { HeadContent, HTMLRender } from '../../components';

import { type IAgreementsPageProps } from '../../modules/agreements/pageProps/@interfaces';

import { imgPrefix } from '../../utils';

import styles from './AgreementsTemplate.module.scss';

export const AgreementsTemplate = ({
  contents,
  footerContent,
}: IAgreementsPageProps) => (
  <>
    <HeadContent
      metaTitle="GetNinjas | Política de Privacidade"
      metaDescription="Política de Privacidade do GetNinjas"
      pageType="pages:agreements"
    />

    <Container>
      <Header.Root onlyLogo />

      <Banner>
        <Row>
          <Col className="col-sm-12 col-md-8 col-md-offset-2">
            <Row className="space-between">
              <Col className="col-sm-3 col-lg-2">
                <Image
                  src={imgPrefix(
                    '/images/agreements/politica-de-privacidade.svg'
                  )}
                  alt="Checklist Icon"
                  width={96}
                  height={118}
                  priority
                />
              </Col>
              <Col>
                <Paragraph title="Política de Privacidade Getninjas" size="1" />

                <HTMLRender className={styles.text}>{contents}</HTMLRender>
              </Col>
            </Row>
          </Col>
        </Row>
      </Banner>
    </Container>

    <Footer data={footerContent} />
  </>
);
