import { Container, Header } from 'darrius';

import { HeadContent, Toast } from '../../components';

import { SignInPhoneNumber } from './SignInPhoneNumber/SignInPhoneNumber';
import { SignInPin } from './SignInPin/SignInPin';

import { type ISignInProps } from '../../pages/entrar';
import { useSignIn } from '../../context/SignInContext/SignInContext';

export const SignInTemplate = ({ signIn, redirectTo }: ISignInProps) => {
  const { phoneScreen, pinScreen } = signIn;

  const { hasPhoneNumber, phone, user } = useSignIn();

  function renderSteps() {
    if (hasPhoneNumber) {
      const signInPinProps = {
        user,
        phone,
        i18n: pinScreen,
        path: redirectTo,
      };

      return <SignInPin {...signInPinProps} />;
    }

    return <SignInPhoneNumber {...phoneScreen} />;
  }

  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Entrar"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="signin:show"
      />

      <Container>
        <Header.Root onlyLogo />
      </Container>

      <Container as="main">{renderSteps()}</Container>

      <Toast />
    </>
  );
};
