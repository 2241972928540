import { Header, Container, Row, Col, Paragraph } from 'darrius';

import { Loading, HeadContent } from '../../components';

import { Shell, ShellHeader, ShellContent } from './Shell';

export const SurveyLoading = () => (
  <>
    <HeadContent
      metaTitle="GetNinjas | Orçamento de Profissionais Confiáveis no GetNinjas.com.br"
      metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
      pageType="pages:survey/loading"
    />

    <Shell>
      <ShellHeader>
        <Container>
          <Header.Root onlyLogo />
        </Container>
      </ShellHeader>

      <ShellContent>
        <Container>
          <Row className="center-sm middle-sm space-top">
            <Col className="start-sm col-sm-9 col-md-5 col-lg-4">
              <Paragraph
                title="Buscando avaliações"
                text="É rapidinho, fique aqui"
                size="2"
                className="no-margin"
              />
            </Col>

            <Col className="col-sm-3 col-md-2 first-md">
              <Loading />
            </Col>
          </Row>
        </Container>
      </ShellContent>
    </Shell>
  </>
);
