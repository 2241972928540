import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { AxiosError, type AxiosResponse } from 'axios';
import { Container, Header, Paragraph } from 'darrius';

import { axiosInstance } from '../../utils/axiosInstance';
import { truncate } from '../../utils';

import { HeadContent } from '../../components';

import { Card } from './Card/Card';
import { Conversations } from './Conversations/Conversations';
import { Chat } from './Chat/Chat';

import { type TStates } from './Conversations/@interfaces';
import { type IChatForConciergeProps } from '../../modules/chat/concierge/@interfaces';
import {
  type IMessages,
  type IConversation,
} from '../../modules/chat/shared/@interfaces';

import styles from './ChatForConciergeTemplate.module.scss';

export const ChatForConciergeTemplate = (props: IChatForConciergeProps) => {
  const router = useRouter();

  const [userId, setUserId] = useState<string>(props.selectedUser || null);
  const [profileId, setProfileId] = useState<string>(
    props.selectedProfile || null
  );

  const [conversationId, setConversationId] = useState(
    props.selectedConversation || ''
  );

  const [conversations, setConversations] = useState<IConversation[]>(
    props.conversations || []
  );

  const [unreadConversations, setUnreadConversations] = useState<number>(
    props.unreadConversations || 0
  );

  const [isNotificationEnabled, setIsNotificationEnabled] =
    useState<boolean>(false);

  const [state, setState] = useState<TStates>(
    props.selectedProfile ? 'success' : 'pristine'
  );
  const [errorMessage, setErrorMessage] = useState('');

  const hasNoConversations = state === 'success' && conversations.length === 0;
  const hasNoProfileSelected = state === 'pristine';

  const getConversationInfoMessage = () => {
    if (hasNoProfileSelected) {
      return 'Selecione um Pro Institucional para verificar suas mensagens';
    }

    if (hasNoConversations) {
      return 'Não há conversas para este profissional institucional';
    }

    return null;
  };
  const conversationInfoMessage = getConversationInfoMessage();

  const getChatInfoMessage = () => {
    if (!userId) {
      return 'Selecione um Pro Institucional para poder selecionar uma conversa';
    }

    if (conversationId.length <= 0) {
      return 'Selecione uma conversa para ver o chat';
    }

    return null;
  };

  const chatInfoMessage = getChatInfoMessage();

  const handleActivateNotifications = () => {
    setIsNotificationEnabled(true);
  };

  const handleNotification = async () => {
    try {
      const { data } = await axiosInstance<IMessages>({
        url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/v1/chat/conversations`,
        params: {
          profileId,
          userId,
        },
        method: 'GET',
      });
      setConversations(data.conversations);
      setUnreadConversations(data.unreadConversations);
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfileClick = async (user: string, profile: string) => {
    setState('loading');
    setErrorMessage('');
    setUserId(user);
    setProfileId(profile);
    setConversationId('');
    setConversations([]);
    router.push(`/chat/list/${profile}`, undefined, { shallow: true });
    try {
      const { data } = await axiosInstance<IMessages>({
        url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/v1/chat/conversations`,
        params: {
          profileId: profile,
          userId: user,
        },
        method: 'GET',
      });
      setState('success');
      setConversations(data.conversations);
      setUnreadConversations(data.unreadConversations);
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response != null) {
          const { status } = error.response as AxiosResponse;
          if (status === 401) {
            setState('error');
            setErrorMessage(
              'O token de autenticação do profissional mudou. Por favor, consulte o time de tecnologia para suporte'
            );
          }
        } else {
          setState('error');
        }
      }
      setConversations([]);
    }
  };

  const handleConversationClick = (conversation: IConversation) => {
    setConversationId(conversation.chatId);
    router.push(
      `/chat/list/${profileId}/conversations/${conversation.chatId}`,
      undefined,
      { shallow: true }
    );
    if (conversation.isUnread) {
      const newConversation = { ...conversation, isUnread: false };
      setUnreadConversations(
        (numberOfUnreadConversations) => numberOfUnreadConversations - 1
      );
      setConversations((prevConversations) => {
        const updatedConversations = [...prevConversations];
        const foundConversation = updatedConversations.findIndex(
          (currentConversation) =>
            currentConversation.chatId === newConversation.chatId
        );
        updatedConversations[foundConversation] = newConversation;

        return updatedConversations;
      });
    }
  };

  useEffect(() => {
    setIsNotificationEnabled(Notification.permission === 'granted');
  }, []);

  return (
    <>
      <HeadContent
        metaTitle={`${unreadConversations > 0 ? `(${unreadConversations}) ` : 'Chat |'} ${userId == null ? 'Lista' : props.profiles.find((profile) => profile.userId === userId).name}`}
        metaDescription="Lista de conversas do chat"
        canonicalLink=""
        pageType="chat-concierge:show"
        noindex
      />

      <Header.Root onlyLogo />

      <div className={styles.background}>
        <Container>
          <main className={styles.main}>
            <section>
              <Paragraph size="2" title="Escolha um Pro Institucional" />
              <ul className={styles.list}>
                {props.profiles.length ? (
                  props.profiles.map((profile) => (
                    <Card.Root
                      key={profile.id}
                      isSelected={userId === profile.userId}
                      id={profile.id}
                      onClick={(event) => {
                        event.preventDefault();
                        handleProfileClick(profile.userId, profile.id);
                      }}
                    >
                      <Card.Avatar image={profile.name} />
                      <Card.Content>
                        <Card.Title>{profile.name}</Card.Title>
                        <Card.Subtitle>{profile.category}</Card.Subtitle>
                        <Card.Text>
                          {truncate(profile.description, 80, '...')}
                        </Card.Text>
                      </Card.Content>
                    </Card.Root>
                  ))
                ) : (
                  <div className={styles.message}>
                    Nenhum profissional institucional encontrado
                  </div>
                )}
              </ul>
            </section>
            <section>
              <Conversations
                state={state}
                conversations={conversations}
                conversationId={conversationId}
                handleConversationClick={handleConversationClick}
                errorMessage={errorMessage}
                conversationInfoMessage={conversationInfoMessage}
                isNotificationEnabled={isNotificationEnabled}
                handleActivateNotifications={handleActivateNotifications}
              />
            </section>
            <section className={styles.section}>
              <Paragraph size="2" title="Chat" />
              <Chat
                userId={userId}
                conversationId={conversationId}
                onNotification={handleNotification}
                isNotificationEnabled={isNotificationEnabled}
                className={styles.chat}
              />
              {chatInfoMessage && (
                <p className={styles.message}>{chatInfoMessage}</p>
              )}
            </section>
          </main>
        </Container>
      </div>
    </>
  );
};
