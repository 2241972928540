import Image from 'next/image';

import {
  Header,
  Row,
  Col,
  Paragraph,
  Container,
  Footer,
  SearchBar,
} from 'darrius';

import { HeadContent } from '../../components';

import { type INotFoundPageProps } from '../../modules/notFound/pageProps/@interfaces';

import { imgPrefix, getBlurDataURL } from '../../utils';

export const NotFoundTemplate = (props: INotFoundPageProps) => {
  const { footerContent, headerContent } = props;

  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | Não encontrado"
        metaDescription="página não encontrada"
        pageType="pages:not-found"
      />

      <Header.Root>
        <Header.NavMenu>
          <Header.Link {...headerContent.about} />
          <Header.Link {...headerContent.signup} />
        </Header.NavMenu>
      </Header.Root>

      <Container>
        <Row className="start-sm center-md middle-md top-sm space-between md-between space-top space-bottom">
          <Col className="col-sm-3">
            <Image
              src={imgPrefix('/images/notFound/404.svg')}
              alt="Serviço não encontrado"
              width={276}
              height={276}
              placeholder="blur"
              blurDataURL={getBlurDataURL()}
              priority
              className="responsive-image"
            />
          </Col>

          <Col className="col-sm-12 col-md-6 start-sm">
            <Paragraph
              size="1"
              title="Ops, não encontramos essa página"
              text="Tente novamente utilizando outro termo ou palavras mais genéricas"
            />

            <SearchBar action="/requests/new" />
          </Col>
        </Row>
      </Container>

      <Footer data={footerContent} />
    </>
  );
};
