import {
  Header,
  Row,
  Col,
  Paragraph,
  Container,
  Footer,
  Picture,
} from 'darrius';

import { imgPrefix } from '../../utils';

import { HeadContent } from '../../components';
import { type IFooterContent } from '../../locales/@interfaces/shared/footerContent';

interface IUnderConstructionPageProps {
  footerContent: IFooterContent;
}

export const UnderConstructionTemplate = (
  props: IUnderConstructionPageProps
) => {
  const { footerContent } = props;

  return (
    <>
      <HeadContent
        metaTitle="GetNinjas | En construcción"
        metaDescription="Página en construcción"
        pageType="pages:under-construction"
      />

      <Container>
        <Header.Root onlyLogo />

        <Row className="space-top space-bottom space-between middle-md">
          <Col className="col-sm-3 col-md-offset-1">
            <Picture
              className="full"
              alt="Página en construcción"
              src={imgPrefix({
                sm: '/images/notFound/404.svg',
                md: '/images/notFound/404.svg',
                lg: '/images/notFound/404.svg',
              })}
            />
          </Col>

          <Col className="col-sm-12 col-md-7">
            <Paragraph
              size="1"
              title="Esta página está en construcción!"
              text="Lo sentimos, estamos trabajando para brindarte una mejor experiencia."
            />
          </Col>
        </Row>
      </Container>

      <Footer data={footerContent} />
    </>
  );
};
