import { Header, Container } from 'darrius';

import { HeadContent, Toast } from '../../components';

import { SignInPin } from '../SignIn/SignInPin/SignInPin';

import { type IAuthProps } from '../../pages/requests/[uuid]/auth/user/[userId]/phone/[phoneId]';

export const AuthTemplate = (props: IAuthProps) => {
  const { uuid, user, phone, i18n } = props;

  return (
    <Container>
      <HeadContent
        metaTitle="GetNinjas | Validação de SMS"
        metaDescription="Encontre profissionais confiáveis para pintar, reformar, traduzir um texto, te ensinar matemática, ou também passear com seu cachorro no GetNinjas"
        pageType="auth:show"
        noindex
      />

      <Header.Root onlyLogo />

      <SignInPin
        i18n={i18n}
        phone={phone}
        user={user}
        path={`/requests/${uuid}`}
      />

      <Toast />
    </Container>
  );
};
