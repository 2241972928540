import {
  useEffect,
  useState,
  useContext,
  createContext,
  type ReactNode,
  type ChangeEvent,
} from 'react';

import { formatPhoneNumber } from '../../modules/shared/phoneNumber/utils/formatPhoneNumber';

import { axiosInstance } from '../../utils/axiosInstance';

import { schemaPhone } from '../../schemas/phone';

import { showToast } from '../../components/atoms/Toast/Toast';

import {
  type TAuthUser,
  type IAuthLoginResponse,
} from '../../pages/api/auth/login';

export interface ISignInContext {
  user: TAuthUser | null;
  isPinSending: boolean;
  hasPhoneNumber: boolean;
  phone: {
    onlyNumbers: string;
    formatted: string;
  };
  handleUserPhoneNumber: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSendPinToNumber: () => void;
}

interface ISignInProviderProps {
  children: ReactNode;
}

const NUMBER_IS_INVALID_TEXT =
  'Por favor, insira um número de telefone válido.';

export const SignInContext = createContext({} as ISignInContext);

export const SignInProvider = ({ children }: ISignInProviderProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedNumber, setFormattedNumber] = useState('');
  const [user, setUser] = useState<TAuthUser>(null);
  const [hasPhoneNumber, setHasPhoneNumber] = useState(false);
  const [isPinSending, setIsPinSending] = useState(false);

  async function handleSendPinToNumber() {
    if (!phoneNumber) {
      setHasPhoneNumber(false);

      showToast({
        message: NUMBER_IS_INVALID_TEXT,
        type: 'warning',
        id: 'invalid-number',
      });

      return;
    }

    setIsPinSending(true);

    const { success } = schemaPhone.safeParse({
      phone: phoneNumber,
    });

    if (!success) {
      showToast({
        message: NUMBER_IS_INVALID_TEXT,
        type: 'warning',
        id: 'invalid-number',
      });

      setIsPinSending(false);

      return;
    }

    const requestOptions = {
      url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/auth/login`,
      method: 'POST',
      data: {
        phone: phoneNumber,
        formattedPhone: formattedNumber,
      },
    } as const;

    try {
      const authLoginResponseData =
        await axiosInstance<IAuthLoginResponse>(requestOptions);

      const { data, message, status } = authLoginResponseData.data;

      if (status === 'error') {
        showToast({
          message,
          type: 'error',
          id: 'error-sending-pin',
        });

        return;
      }

      showToast({
        message,
        type: 'success',
        id: 'success-sending-pin',
      });

      setUser(data.user);
      setHasPhoneNumber(true);
    } catch (error) {
      showToast({
        message: error.response.data.message,
        type: 'error',
        id: 'error-sending-pin',
      });

      setHasPhoneNumber(false);

      setUser(null);
    } finally {
      setIsPinSending(false);
    }
  }

  function handleUserPhoneNumber(event: ChangeEvent<HTMLInputElement>) {
    const phoneValue = event.target.value;

    setPhoneNumber(phoneValue);
    setFormattedNumber(phoneValue);
  }

  useEffect(() => {
    const onlyPhoneNumbers = phoneNumber.replace(/\D/g, '');
    const containsElevenNumber = onlyPhoneNumbers.length === 11;

    if (containsElevenNumber) {
      const formattedNumberValue = formatPhoneNumber({
        phone: phoneNumber,
      });

      setFormattedNumber(formattedNumberValue);
    }
  }, [phoneNumber]);

  return (
    <SignInContext.Provider
      value={{
        user,
        isPinSending,
        hasPhoneNumber,
        phone: {
          onlyNumbers: phoneNumber,
          formatted: formattedNumber,
        },
        handleUserPhoneNumber,
        handleSendPinToNumber,
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

export function useSignIn() {
  return useContext(SignInContext);
}
