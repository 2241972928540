import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import classNames from 'classnames';

import { Col, Container, Header, Icon, Paragraph, Row } from 'darrius';

import { axiosInstance } from '../../utils/axiosInstance';
import { getFlashTitle } from '../../utils/getFlashTitle';

import { HeadContent } from '../../components';

import { Conversations } from './Conversations/Conversations';
import { Chat } from './Chat/Chat';

import styles from './ChatTemplate.module.scss';

import { type IChatProps } from '../../modules/chat/@interfaces';
import { type ConversationActionEvent } from 'talkjs/all';
import {
  type IMessages,
  type IConversation,
} from '../../modules/chat/shared/@interfaces';

// TODO: Revisar CSS dos templates de Chat
// TODO: Adequar template do concierge para o mobile, seguindo a linha do chat web
export const ChatTemplate = (props: IChatProps) => {
  const router = useRouter();

  const [conversationId, setConversationId] = useState(
    props.selectedConversation || ''
  );

  const [conversations, setConversations] = useState<IConversation[]>(
    props.conversations || []
  );

  const [unreadConversations, setUnreadConversations] = useState<number>(
    props.unreadConversations || 0
  );

  const [isNotificationEnabled, setIsNotificationEnabled] =
    useState<boolean>(false);

  const showChat = conversationId !== '';

  const userId = props.selectedUser || null;

  const handleActivateNotifications = () => {
    setIsNotificationEnabled(true);
  };

  const handleNotification = async () => {
    try {
      const { data } = await axiosInstance<IMessages>({
        url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/v1/chat/messages/${userId}`,
        method: 'GET',
      });
      setConversations(data.conversations);
      setUnreadConversations(data.unreadConversations);
    } catch (err) {
      console.error(err);
    }
  };

  const handleConversationClick = (conversation: IConversation) => {
    setConversationId(conversation.chatId);
    router.push(`/mensagens/${conversation.chatId}`, undefined, {
      shallow: true,
    });
    if (conversation.isUnread) {
      const newConversation = { ...conversation, isUnread: false };
      setUnreadConversations(
        (numberOfUnreadConversations) => numberOfUnreadConversations - 1
      );
      setConversations((prevConversations) => {
        const updatedConversations = [...prevConversations];
        const foundConversation = updatedConversations.findIndex(
          (currentConversation) =>
            currentConversation.chatId === newConversation.chatId
        );
        updatedConversations[foundConversation] = newConversation;

        return updatedConversations;
      });
    }
  };

  useEffect(() => {
    setIsNotificationEnabled(Notification.permission === 'granted');
  }, []);

  const handleChatGoBack = (handler: ConversationActionEvent) => {
    if (handler.action === 'goback') {
      setConversationId('');
      router.push(`/mensagens`, undefined, { shallow: true });
    }
  };

  const mainClasses = classNames({
    [styles.main]: true,
    [styles['view-transition']]: showChat,
  });

  const flashTitle = getFlashTitle(unreadConversations);

  return (
    <>
      <HeadContent
        flashTitle={flashTitle}
        metaTitle="GetNinjas | Mensagens"
        metaDescription="Lista de conversas do chat"
        canonicalLink="/mensagens"
        pageType="chat-cli:show"
        noindex
      />

      <Header.Root onlyLogo />

      <div className={styles.background}>
        <Container className={styles.container}>
          {conversations.length === 0 && (
            <Row className="center-sm">
              <Col className={`${styles['no-messages']} col-sm-12 col-md-6`}>
                <Icon
                  src={`${process.env.NEXT_PUBLIC_ASSETS_PREFIX}/images/chat/contact.svg`}
                  size={106}
                  alt="Ícone de um celular"
                />
                <Paragraph
                  title="Sem mensagens"
                  text="Este espaço está um pouco calmo agora, mas não se preocupe! Suas mensagens com os profissionais aparecerão aqui."
                  size="3"
                  headline
                />
              </Col>
            </Row>
          )}
          {conversations.length > 0 && (
            <main className={styles.wrapper}>
              <div className={mainClasses}>
                <section className={styles.messages}>
                  <Conversations
                    state="success"
                    conversations={conversations}
                    conversationId={conversationId}
                    handleConversationClick={handleConversationClick}
                    isNotificationEnabled={isNotificationEnabled}
                    handleActivateNotifications={handleActivateNotifications}
                  />
                </section>
                <section className={styles.chat}>
                  <Paragraph
                    size="2"
                    title="Chat"
                    className={styles['chat-title']}
                  />
                  <Chat
                    userId={userId}
                    conversationId={conversationId}
                    onNotification={handleNotification}
                    isNotificationEnabled={isNotificationEnabled}
                    onGoBack={handleChatGoBack}
                    className={styles['chat-content']}
                  />
                  {conversationId.length === 0 && (
                    <Row className="center-sm">
                      <Col className={`${styles['no-messages']} col-sm-12`}>
                        <Icon
                          src={`${process.env.NEXT_PUBLIC_ASSETS_PREFIX}/images/chat/select.svg`}
                          size={106}
                          alt="Ícone de uma mão pegando uma folha"
                        />
                        <Paragraph
                          title="Selecione uma mensagem"
                          text="Selecione uma conversa existente na lista de contatos para exibir suas mensagens aqui."
                          size="3"
                          headline
                        />
                      </Col>
                    </Row>
                  )}
                </section>
              </div>
            </main>
          )}
        </Container>
      </div>
    </>
  );
};
