/* eslint-disable jsx-a11y/label-has-associated-control */
import { v4 as uuidv4 } from 'uuid';

import {
  useState,
  type ChangeEvent,
  type MouseEvent,
  type FocusEvent,
  useEffect,
} from 'react';
import { MdClose, MdSearch } from 'react-icons/md';

import { useDebounce } from '../../../hooks/useDebounce';

import { diacriticsTranslation, triggerEvent } from '../../../utils';

import styles from './SearchCategory.module.scss';
import classNames from 'classnames';
import { axiosInstance } from '../../../utils/axiosInstance';
import { Loading } from '../../../components';

export interface ISearchCategoriesData {
  id: number;
  url: string;
  displayText: {
    root: string;
    subcategory: string;
  };
}

type TFilterCategoriesState =
  | 'EMPTY_SEARCH'
  | 'SEARCHED'
  | 'NOT_FOUND'
  | 'SEARCHING';

export function SearchCategory() {
  const [isSearchDropdownOpen, setIsSearchDropdownOpen] = useState(false);
  const [isSearchedCategoryNotFound, setIsSearchedCategoryNotFound] =
    useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchBarValue, setSearchBarValue] = useState('');
  const [categoriesSearched, setCategoriesSearched] = useState<
    ISearchCategoriesData[]
  >([]);

  const hasValueOnInput = searchBarValue !== '';

  const searchCleanClassNames = classNames(styles.search__clean, {
    [styles.search__clean_isActived]: hasValueOnInput,
  });

  function triggerSearchBar() {
    triggerEvent('home_search_category-search_bar');
  }

  const filterCategories = async (categoryToSearch: string) => {
    const diacriticsTranslationRegex = new RegExp(
      `[${Object.keys(diacriticsTranslation).join('')}]`,
      'g'
    );

    const categoryToSearchWithoutDiacritics = categoryToSearch.replace(
      diacriticsTranslationRegex,
      (char) => {
        return diacriticsTranslation[char] || char;
      }
    );

    const categoryToSearchIsEmpty = categoryToSearchWithoutDiacritics === '';

    if (categoryToSearchIsEmpty) {
      return {
        state: 'EMPTY_SEARCH' as TFilterCategoriesState,
        data: [],
      };
    }

    const headers = {
      'x-request-id': uuidv4(),
    };

    const { data } = await axiosInstance({
      url: `${process.env.NEXT_PUBLIC_APPLICATION_HOST}/api/v1/search?text=${categoryToSearchWithoutDiacritics}`,
      method: 'GET',
      headers,
    });

    const selectedCategories = (data as unknown as any[]).map(
      (selectedCategory) => ({
        id: selectedCategory.id,
        url: `/${selectedCategory.slug}`,
        displayText: {
          root: selectedCategory.rootName,
          subcategory: selectedCategory.name,
        },
      })
    );

    const categoryIsNotFound = selectedCategories.length === 0;

    if (categoryIsNotFound) {
      return {
        state: 'NOT_FOUND' as TFilterCategoriesState,
        data: [],
      };
    }

    return {
      state: 'SEARCHED' as TFilterCategoriesState,
      data: selectedCategories,
    };
  };

  const onSearchCategory = async (categorySearched: string) => {
    const categoryToSearch = String(categorySearched).trim().toLowerCase();

    setIsSearching(true);
    setIsSearchDropdownOpen(true);

    const { data, state } = await filterCategories(categoryToSearch);

    setIsSearching(false);

    if (state === 'NOT_FOUND') {
      setIsSearchedCategoryNotFound(true);

      triggerEvent('home_search_category-input_not_found', {
        value: categorySearched,
      });

      return;
    }

    if (state === 'EMPTY_SEARCH') {
      setCategoriesSearched([]);
      setIsSearchedCategoryNotFound(false);
      setIsSearchDropdownOpen(false);

      triggerEvent('home_search_category-empty_search', {
        value: categorySearched,
      });

      return;
    }

    setCategoriesSearched(data);
    setIsSearchedCategoryNotFound(false);

    triggerEvent('home_search_category-is_input-value', {
      value: categorySearched,
    });
  };

  const onSearchCategoryDebounce = useDebounce(onSearchCategory, 500);

  function handleSearchCategory(event: ChangeEvent<HTMLInputElement>) {
    const text = event.target.value;

    const isValidText = /^[a-zA-Z0-9\sÀ-ÿçÇ ]*$/.test(text);

    if (!isValidText) {
      setIsSearchedCategoryNotFound(true);

      return;
    }

    setSearchBarValue(text);
    onSearchCategoryDebounce(text);
  }

  const onCleanSearchBar = () => {
    setCategoriesSearched([]);
    setIsSearchedCategoryNotFound(false);
    setSearchBarValue('');
    setIsSearchDropdownOpen(false);
  };

  const handleCleanSearchBar = (
    event: MouseEvent<HTMLElement> | FocusEvent<HTMLAnchorElement, Element>
  ) => {
    event.preventDefault();

    triggerEvent('home_search_category-clean-search');

    onCleanSearchBar();
  };

  useEffect(() => {
    function handleUserClick(event: WindowEventMap['click']) {
      if (
        !document.getElementById('search_bar').contains(event.target as Node)
      ) {
        onCleanSearchBar();
      }
    }

    const clickListenerEvent = window.addEventListener(
      'click',
      handleUserClick
    );

    return () => {
      window.removeEventListener('click', clickListenerEvent as any);
    };
  }, []);

  return (
    <div className={styles['search-category']} id="search_bar">
      <div className={styles['search-category__search']}>
        <label className={styles.search__label}>
          <MdSearch />
          <input
            type="text"
            placeholder="O que você precisa?"
            data-testid="search__input"
            value={searchBarValue}
            onClick={triggerSearchBar}
            onChange={handleSearchCategory}
          />
        </label>
        <button
          onClick={handleCleanSearchBar}
          className={`${searchCleanClassNames} ${styles['search-category__clean-button']}`}
          data-testid="search__clean-button"
        >
          <MdClose />
        </button>
      </div>

      {isSearchDropdownOpen && (
        <div className={`${styles['search-category__dropdown']} col-12`}>
          <ul>
            {isSearchedCategoryNotFound ? (
              <li>
                <span>Nenhum resultado encontrado</span>
              </li>
            ) : null}
            {isSearching ? (
              <li className={styles['search-category__dropdown-loading']}>
                <p>Procurando resultados</p>
                <Loading />
              </li>
            ) : null}
            {!isSearchedCategoryNotFound && !isSearching ? (
              <>
                {categoriesSearched.map((category) => (
                  <a
                    key={category.id.toString()}
                    href={category.url}
                    onClick={function triggerSelectCategory() {
                      triggerEvent('home_search_category-select_category', {
                        slug: category.url,
                        name:
                          category.displayText.subcategory +
                          ' em ' +
                          category.displayText.root,
                      });
                    }}
                  >
                    <li>
                      <span>
                        {category.displayText.subcategory} em{' '}
                        <strong>
                          <i>{category.displayText.root}</i>
                        </strong>
                      </span>
                    </li>
                  </a>
                ))}
              </>
            ) : null}
          </ul>
        </div>
      )}
    </div>
  );
}
