import { useState } from 'react';

import axios from 'axios';

import { showToast } from '../../components/atoms/Toast/Toast';

import {
  type TPinValidateParams,
  type IPinValidateResponse,
} from '../../pages/api/auth/pin/validate';

type TOnSubmitPinParams = TPinValidateParams;

interface IOnResendPin {
  phone: string;
  userId: string;
}

export function useAuth() {
  const [isShouldResetCountdown, setIsShouldResetCountdown] = useState(false);
  const [isCountdownOver, setIsCountdownOver] = useState(false);

  function onResetCountdown() {
    setIsShouldResetCountdown(false);
  }

  function onCountdownOver() {
    setIsCountdownOver(true);
  }

  async function onSubmitPin({ pin, phone, user }: TOnSubmitPinParams) {
    try {
      const { status } = await axios.post<IPinValidateResponse>(
        `${process.env.NEXT_PUBLIC_API_CLIENTS}/auth/pin/validate`,
        { pin, phone, user }
      );

      if (status === 204) {
        showToast({
          message: 'Telefone validado com sucesso, você será redirecionado...',
          type: 'success',
          id: 'pin-success',
        });

        return {
          success: true,
        };
      }
    } catch (error) {
      if (error.response.status === 422) {
        showToast({
          message: error.response.data.message,
          type: 'warning',
          id: 'pin-warning',
        });

        return {
          success: false,
        };
      }

      showToast({
        message: error.response.data.message,
        type: 'error',
        id: 'pin-error',
      });

      return {
        success: false,
      };
    }
  }

  async function onResendPin({ phone, userId }: IOnResendPin) {
    try {
      setIsShouldResetCountdown(true);
      setIsCountdownOver(false);

      await axios.post(
        `${process.env.NEXT_PUBLIC_API_CLIENTS}/auth/pin/resend`,
        { phone, userId }
      );

      showToast({
        message: 'PIN reenviado com sucesso',
        type: 'success',
        id: 'resend-pin-success',
      });
    } catch (error) {
      if (error.response.status === 422) {
        showToast({
          message: error.response.data.message,
          type: 'warning',
          id: 'resend-pin-warning',
        });
      }
    }
  }

  return {
    onSubmitPin,
    onResendPin,
    countDown: {
      onResetCountdown,
      onCountdownOver,
      isShouldResetCountdown,
      isCountdownOver,
    },
  };
}
