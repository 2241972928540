import { useState } from 'react';
import Image from 'next/image';

import {
  Breadcrumb,
  Button,
  Col,
  Container,
  Footer,
  Header,
  List,
  ListItem,
  Paragraph,
  Row,
  Tag,
  TextLink,
} from 'darrius';

import { FlatCard, FlatCardBody, HeadContent } from '../../components';

import { type IFaqArticleProps } from '../../modules/faq/pageProps/getFaqArticlePageProps/@interfaces';

export const FaqArticleTemplate = (props: IFaqArticleProps) => {
  const {
    meta,
    headerContent,
    breadcrumbs,
    question,
    answers,
    feedback,
    relatedQuestions,
    footerContent,
  } = props;

  const [isFeedbackDone, setIsFeedbackDone] = useState(false);

  const handleFeedbackClick = () => {
    setIsFeedbackDone(true);
  };

  return (
    <>
      <HeadContent
        metaTitle={meta.title}
        metaDescription={meta.description}
        canonicalLink={meta.canonical}
        pageType="faq_article"
      />

      <Header.Root>
        <Header.NavMenu>
          <Header.Link {...headerContent.about} />
          <Header.Link {...headerContent.signup} />
        </Header.NavMenu>
      </Header.Root>

      <Container as="main" className="sitemap-page">
        <Breadcrumb breadcrumbs={breadcrumbs.items} className="hide-sm" />

        <script
          id="faq-article-configs"
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: breadcrumbs.structuredData,
          }}
        />

        <Paragraph title={question} />

        <Row>
          {answers.map((answer) => (
            <Col key={answer.id} className="col-sm-12 col-md-10">
              {(answer.title || answer.text) && (
                <Paragraph
                  size="2"
                  title={answer.title}
                  text={answer.text}
                  className="paragraph-3"
                />
              )}

              {answer.images && (
                <Row className="center-sm">
                  {answer.images.map((image) => (
                    <Col
                      key={image.id}
                      className="col-sm-12 col-md-7 space-bottom"
                    >
                      <Image
                        src={image.src}
                        alt={image.alt}
                        width={image.width}
                        height={image.height}
                      />
                    </Col>
                  ))}
                </Row>
              )}

              {answer.links && (
                <List>
                  {answer.links.map((link) => (
                    <ListItem key={link.id}>
                      - <TextLink url={link.url} text={link.text} />
                    </ListItem>
                  ))}
                </List>
              )}
            </Col>
          ))}
        </Row>

        <section className="space-top">
          <Paragraph
            title={feedback.title}
            text={isFeedbackDone ? feedback.thankYou : ''}
            size="3"
          />

          <Row>
            <Col className="col-sm-6 col-md-3 col-lg-2">
              <Row className="space-between">
                {!isFeedbackDone &&
                  feedback.options.map((option, index) => (
                    <Col key={option} className="col-sm-6">
                      <Button.Root
                        variation="secondary"
                        onClick={handleFeedbackClick}
                        size="small"
                        className={`feedback-${index}`}
                      >
                        {option}
                      </Button.Root>
                    </Col>
                  ))}
              </Row>
            </Col>
          </Row>
        </section>

        {relatedQuestions && (
          <section className="space-top">
            <Paragraph title={relatedQuestions.title} size="2" />

            <Row className="space-between">
              {relatedQuestions.items.map((relatedQuestion) => (
                <Col key={relatedQuestion.id} className="col-sm-12 col-md-6">
                  <a href={relatedQuestion.link} className="related-question">
                    <FlatCard selectable>
                      <FlatCardBody>
                        <Tag className="action">{relatedQuestion.category}</Tag>

                        <Paragraph
                          title={relatedQuestion.title}
                          className="space-top"
                          size="3"
                        />
                      </FlatCardBody>
                    </FlatCard>
                  </a>
                </Col>
              ))}
            </Row>
          </section>
        )}
      </Container>

      <Footer data={footerContent} />
    </>
  );
};
