import { useEffect, useState } from 'react';

import { Banner, Button, Col, Row } from 'darrius';

import Image from 'next/image';

import { Countdown, Loading, Otp } from '../../../components';

import { imgPrefix, navigate } from '../../../utils';

import { useAuth } from '../../../hooks/auth/useAuth';

import styles from './SignInPin.module.scss';

import { type TAuthUser } from '../../../pages/api/auth/login';
import { type TOtpPinStatus } from '../../../components/atoms/Otp/Otp';
import { type TPinScreen } from '../../../pages/entrar';

interface ISignInPinProps {
  path?: string;
  i18n: TPinScreen;
  user: TAuthUser;
  phone: {
    onlyNumbers: string;
    formatted: string;
  };
}

export function SignInPin({ path = '/', user, phone, i18n }: ISignInPinProps) {
  const [pin, setPin] = useState('');
  const [isPinSending, setIsPinSending] = useState(false);
  const [pinStatus, setPinStatus] = useState<TOtpPinStatus>('default');
  const [previouslyEnteredPin, setPreviouslyEnteredPin] = useState('');

  const { subtitle, resendSms, title } = i18n;

  const { onSubmitPin, onResendPin, countDown } = useAuth();

  const {
    onResetCountdown,
    onCountdownOver,
    isShouldResetCountdown,
    isCountdownOver,
  } = countDown;

  useEffect(() => {
    const handlePin = async () => {
      if (pin.length === 4 && pin !== previouslyEnteredPin) {
        setPreviouslyEnteredPin(pin);
        setIsPinSending(true);

        const { success } = await onSubmitPin({
          phone: phone.onlyNumbers,
          pin,
          user: {
            id: user.id,
            name: user.name,
            phone: phone.formatted,
            avatar: user.avatar,
          },
        });

        if (success) {
          setPinStatus('success');
          setIsPinSending(false);

          const timer = setTimeout(() => {
            const link = `${process.env.NEXT_PUBLIC_HOST}${path}`;

            navigate(link);
          }, 2000);

          return () => {
            clearTimeout(timer);
          };
        }

        setPinStatus('error');
        setIsPinSending(false);
      }
    };

    handlePin();
  }, [onSubmitPin, path, phone, pin, previouslyEnteredPin, user]);

  async function handleResendPin() {
    await onResendPin({
      phone: phone.onlyNumbers,
      userId: user.id,
    });
  }

  function handleChangePin(pinValue: string) {
    setPinStatus('default');
    setPin(pinValue);
  }

  return (
    <Banner>
      <Row>
        <Col className={styles.auth__content}>
          <Image
            src={imgPrefix('/images/auth/sms-code.svg')}
            alt=""
            height={96}
            width={96}
          />

          <Banner>
            <h1>{title}</h1>
            <p>
              {subtitle} <strong>{phone.formatted}</strong>
            </p>

            <Otp
              handleChangePin={handleChangePin}
              isPinSending={isPinSending}
              pinStatus={pinStatus}
            />

            {isPinSending && (
              <div className={styles.pin_loading}>
                <Loading />
              </div>
            )}

            <Countdown
              onIsCountdownOver={onCountdownOver}
              timeToCountDown={resendSms.countdown.timeInSeconds}
              isShouldResetCountdown={{
                canReset: isShouldResetCountdown,
                onReset: onResetCountdown,
              }}
              textToShow={{
                prefix: resendSms.countdown.text.prefix,
                suffix: resendSms.countdown.text.suffix,
              }}
            />

            <Button.Root
              onClick={handleResendPin}
              className={styles.resend_button}
              variation="clear"
              disabled={!isCountdownOver || isPinSending}
              size="small"
            >
              {resendSms.button.text}
            </Button.Root>
          </Banner>
        </Col>
      </Row>
    </Banner>
  );
}
